<template>
  <div class="box">
    <!-- <img class="box_logo" src="../../assets/image/top-logo.png" alt=""> -->
    <div class="container">
      <h1>Privacy Policy</h1>
      <!-- <p class="up_date" style="font-size: .0729rem;"><strong>DATED: 19 March 2024</strong></p> -->
      <ul class="ul_1">
          <p style=" line-height: 30px;">Your privacy is important to  Insomniac Games Inc  and we take our responsibility of caring for it seriously. This policy describes how  Insomniac Games Inc  (“DF”) processes the personal information we collect about you when you use our products and services with  Insomniac Games Inc   (collectively, "Services" ).</p>
          <p style="margin: .1563rem; line-height: 30px;">Now the Game is owned and operated by  Insomniac Games Inc (5940 South Rainbow Boulevard Las Vegas, NV 89118) .</p>
        <li>
          <p><strong>Information We Collect</strong></p>
          <p style=" line-height: 30px;">The information we collect depends on what Services you use and how you use them.</p>
          <ul class="ul_2">
            <li style="font-weight: bold;">This Privacy Policy is issued on behalf of Wild Oaks Farrier Service LLC so when we mention "Company", "we", "us" or "our" in this Privacy Policy, we are referring to the relevant company in the Wild Oaks Farrier Service LLC responsible for processing your data.</li>
            <p style="background-color: rgb(204 204 204);">ⓘ We collect information that you give us directly, such as when you create an Account, set up security questions, make purchases, or contact  Help.</p>
            <p>We collect information that you provide us, such as:</p>
            <ul class="ul_3">
              <li>Your Account information, including your email address, username, real name, country, date of birth, telephone number and password;</li>
              <li>Security questions and answers;</li>
              <li>Information you provide in response to surveys about our Services;</li>
              <li>Information you provide when seeking help from us, such as your name, telephone number, and records (including call recordings) of the issues you experience; and</li>
              <li>Billing information, such as your name, payment card number, payment account details.</li>
            </ul>
            <li>
              <p style="font-weight: bold;">Information You Provide When Playing Online</p>
              <p style=" background-color: rgb(204 204 204);">ⓘ When you play online, we may collect information about your gaming profile, your gameplay..</p>
              <p >When you play our online games, your in-game profile information, gameplay, and statistics are collected and some may be visible in-game. In some of our titles, we may record your gameplay and replay it to other players in-game and/or make it visible to other players outside the game in other DF Services.</p>
            </li>
            <li>
              <p style="font-weight: bold;">Other Information We Collect When You Use Our Services</p>
                <p style="background-color: rgb(204 204 204);">ⓘ We may collect other information when you use our Services, such as information about your device, hardware and software information, IP address, or browser information.</p>
                <p>We may collect other information automatically when you use our Services, such as:</p>
                <ul class="ul_2">
                    <ul class="ul_3">
                      <li>IP address;</li>
                      <li>Information about your device, hardware, and software, such as your hardware identifiers, mobile device identifiers (like Apple Identifier for Advertising [IDFA], or Android Advertising ID [AAID]), platform type, settings and components;</li>
                      <li>Approximate geolocation data (derived from IP or device settings);</li>
                      <li>Browser information, including your browser type and the language preference;</li>
                      <li>Referring and exit pages, including pages viewed and other interactions with web content;</li>
                      <li>Details about what Services you purchase or obtain, and your use of them;</li>
                      <li>Device event information, including crash reports, request and referral URLs, and system activity details (e.g., whether you encountered an error playing our games or lost Internet access); and</li>
                      <li>Other information (such as your likeness) that you may provide as part of your participation in live events.</li>
                    </ul>
                    <p>We also may collect and store information locally on your device, using mechanisms like cookies, browser web storage (including HTML 5), and application data caches.</p>
                </ul>
            </li>
          </ul>
          
        </li>
        <li>
          <p><strong>How We Collect Your Information</strong></p>
          <p style=" line-height: 30px; background-color: rgb(204 204 204);">ⓘ We use various technologies to deliver and measure the effectiveness of our Services, to serve advertisements, and to enhance your player experience. These technologies include cookies and similar technologies, as well as analytics, advertising, anti-cheat, and anti-fraud technologies.</p>
          <p style=" line-height: 30px;">We and third parties use technologies described below to collect information to deliver our Services.</p>
          <ul class="ul_2">
            <li>
              <p><strong> Cookies and Similar Technologies</strong></p>
              <p>Cookies are small text files stored on your Internet browser. We and our third-party partners use cookies and similar tracking technologies to help us understand things like what web pages, features, or ads you view and what games you play. This information helps us keep track of your shopping cart, measure the effectiveness of our advertising, make sure you don't see the same ad repeatedly, and otherwise enhance your experience.</p>
              <p style="margin: 30px 0;">We may use tracking pixels or clear GIFs to collect information about your use of our Services and your response to our marketing emails. We use Internet log files (which contain technical data such as your IP address) to monitor traffic on our Services, troubleshoot technical problems, detect and prevent fraud, and enforce our User Agreement.</p>
              <p>We also use Analytics, Advertising, Anti-Cheat, and Anti-Fraud technologies in our Services. These technologies may utilize the same cookies or similar mechanisms as further described below.</p>
            </li>
            <li>
              <p><strong>Analytics Technologies</strong></p>
              <p>We use internal and third-party analytics technologies to collect information about how you use our Services, your computer or device and the health of our Services.</p>
              <p>For instance, we may collect and store data from your computer or device when you use our Services. This information includes technical and related information about your computer, device, and operating and/or network systems (such as IP Address and device identifiers); information about your engagement with certain features and services; gameplay and usage statistics; as well as system interactions and peripheral hardware. We may also collect and store data from your device to create probabilistic identifiers for the purposes of analyzing the services accessed by our players across devices. If you play a game offline without connecting to the Internet, this data will be stored on your device and transmitted to us when your device connects to the Internet. If you participate in online Services, we may also collect, use, store, transmit and publicly display your personal and/or statistical data regarding your game play (including scores, rankings and accomplishments), or identify content that is created and shared by you with other players.</p>
            </li>
            <li>
              <p><strong>Ad Serving Technologies</strong></p>
              <p>Some of our Services employ ad serving technologies that use cookies, clear GIFs, web beacons, tracking pixels, SDK, API, and other similar technologies to deliver offers and advertising to you within DF Services as well as on third-party platforms and sites, and to measure the performance of our advertising campaigns. Some of these technologies can sync or connect behavior across different websites, mobile apps and devices to tailor offers and advertising to your interests. For instance, you may be presented with an offer for a game we think you might enjoy. Or, if you express interest in a game, you may receive an advertisement later for that game or similar Services on DF.</p>
              <p>These technologies collect and use information so we can serve appropriate offers and advertising to you, to measure effectiveness and distribution of these offers and advertising, and to understand how users interact with our Services.This information may include:</p>
              <ul class="ul_3">
                <li>Advertisements served and the location of the ad;</li>
                <li>Length of time an advertisement was visible;Domain type, IP address, size of the advertisement;</li>
                <li>Your interaction, if any, with the ad; and</li>
                <li>Advertisement response.</li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Anti-Cheat and Fraud Prevention Technologies</strong>
              </p>
              <p>We strive to provide a safe and fair gaming environment for all players. When you use our Services, we or third parties may use cookies and similar technologies and collect data about your machine or device, to create a machine "fingerprint" or "hash" of your machine components, for fraud prevention, security and authentication purposes. We may also monitor publicly-available information, third-party sites, and/or use anti-cheat technology within our Services, including automated anti-fraud and abuse algorithms. If you believe your access to our games and/or Services was blocked by this technology in error, please contact us at support@Insomniacd.com.</p>
            </li>
          </ul>
        </li>
        <li>
          <p><strong>How We Use Your Information</strong></p>
          <p style=" line-height: 30px; background-color: rgb(204 204 204);">ⓘ We use your information to operate our Services, to improve your game experience, to provide you customer support, to serve and measure the effectiveness of advertising, and to personalize our communications with you.</p>
          <p style=" line-height: 30px;">We use your personal information, both individually and combined together, in the following ways.</p>
          <p><strong>To operate our business and to enhance and personalize your game experience, including to:</strong></p>
          <ul class="ul_2">
            <ul class="ul_3">
              <li><p>Operate, analyze, improve, and develop our games and Services;</p></li>
              <li><p>Undertake research and analysis to develop and demonstrate our technology, and to better understand our players;</p></li>
              <li><p>Populate online leaderboards and enable online matchmaking;</p></li>
              <li><p>Provide replays to you and other players via spectator modes;</p></li>
              <li><p>Facilitate sharing on social networks;</p></li>
              <li><p>Manage and serve advertising in-game and on third-party platforms;</p></li>
              <li><p>Measure the effectiveness and distribution of advertising, including understanding who engages with our Services and how they interact with our Services;</p></li>
              <li><p>Measure the health of our Services;</p></li>
              <li><p>Detect security incidents, protect against malicious, deceptive, fraudulent or illegal activity, and prosecute those responsible for that activity;</p></li>
              <li><p>Set up and maintain accounts for which you register;</p></li>
              <li><p>Provide game experiences that you may like, including personalizing your game experience;</p></li>
              <li><p>Set up and facilitate loyalty programs;</p></li>
              <li>
                <p>Facilitate your gameplay on multiple devices and/or platforms, as applicable;</p>
                <p>Identify, fix, and troubleshoot bugs or functionality errors;</p>
                <p>Provide software updates;</p>
              </li>
              <li><p>Authenticate and activate your games and save game-play data, including progress;</p></li>
              <li><p>Perform audits, transactions, and other compliance activities;</p></li>
              <li><p>Adhere to your preferences and to deliver content;</p></li>
              <li><p>Help keep our Services safe and fair, resolve disputes, investigate and help curb fraud and illegal behavior, comply with the law, and to enforce our agreements and policies; and</p></li>
              <li><p>Other purposes you consent to, are notified of, or are otherwise disclosed to you when you provide personal information.</p></li>
            </ul>
          </ul>
          <p><strong>To provide you support, including to:</strong></p>
          <ul class="ul_2">
            <ul class="ul_3">
              <li><p>Help identify and troubleshoot problems with your account or Services;</p></li>
              <li><p>Facilitate surveys or questionnaires;</p></li>
              <li><p>Communicate with you and respond to your specific requests or questions; and</p></li>
              <li><p>Manage and send you confirmations and important information about your account, Services, purchases, and warranties.</p></li>
            </ul>
          </ul>
          <p><strong>To personalize our communications with you, including to:</strong></p>
          <ul class="ul_2">
            <ul class="ul_3">
              <li><p>Provide you with relevant DF content, recommendations, information or offers; and</p></li>
              <li><p>Provide you with Service updates.</p></li>
            </ul>
          </ul>
          <p><strong>Retention. </strong>We retain the information we collect for as long as necessary to provide our Services, and we may retain that information beyond that period if necessary for legal, operational, or other legitimate reasons.</p>
          <p style="margin: 30px 0;">Where possible, we may also de-identify, anonymize, or aggregate the information we collect, or collect it in a way that does not directly identify you. We may use and share such information as necessary for our business purposes and as permitted by applicable law.</p>
        </li>
        <li>
          <p><strong>Your Choices and Controls</strong></p>
          <p style=" line-height: 30px; background-color: rgb(204 204 204);">ⓘ We provide you with choice and control about our use of your information, including your ability to exercise your data privacy rights.</p>
          <p>We give you meaningful choices when it comes to our collection and use of your information. For example, if you no longer want to receive marketing emails from DF, you may click the unsubscribe link included in the footer of any marketing email we send.</p>
          <p><strong>Verification of account ownership. </strong>To protect your account security and personal information, you may be asked to verify your account ownership prior to us processing your request to exercise your data privacy rights. Verification methods include being logged into your Account, confirming ownership and access to the email address associated with your account, and/or confirming other unique account details.</p>
          <p><strong>Your right to deletion. </strong>You may deactivate your EA Account or delete the personal information associated with your account by contacting us support@Insomniacd.com. If you do so, you will no longer have access to the EA games or Services associated with your account. Note that DF may retain information needed to resolve disputes, enforce our user agreements, protect our legal rights, and comply with technical and legal requirements and constraints related to the security, integrity and operation of our Services. Otherwise, we will retain your personal information for as long as reasonably necessary to provide you with, create, and improve our Services, and to comply with the law.</p>
        </li>
        <li>
          <p><strong>Changes to Our Policy</strong></p>
          <p style=" line-height: 30px; background-color: rgb(204 204 204);">ⓘ This policy will be updated from time to time and we will notify you of material changes.</p>
          <p>We may occasionally update this privacy policy. When we do, we will revise the "last updated" date at the bottom of the policy. If there are material changes to this policy, we will use reasonable efforts to notify you either by prominently posting a notice of such changes before they take effect or by directly sending you a notification.</p>
        </li>
        <li>
          <p><strong>Governing Law</strong></p>
          <p>These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the USA laws.</p>
        </li>
        <li>
          <p><strong>Contact Us</strong></p>
          <p style=" line-height: 30px; background-color: rgb(204 204 204);">ⓘ You may contact us if you have questions about this policy.</p>
          <p>If you have questions about this policy, please contact us in the following ways:</p>
          <p>Address:5940 South Rainbow Boulevard Las Vegas, NV 89118</p>
          <p><strong>Last Updated: March 7, 2024</strong></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RESPONSIBLEGAMEPLAYPOLICY'
}
</script>

<style lang="scss" scoped>
.box {
  background: #fff;
  width: 100%;
  padding-bottom: .4167rem;
  h1 {
    text-align: center;
    margin: .4167rem;
    color: #2fccbf;
  }
  p{
    margin: 30px 0;
    line-height: 30px;
  }
}

.container {
  width: 6.7708rem;
  margin: 0 auto;
  .up_date{
    margin-bottom: .2604rem;
  }
  span{
    font-size: .0729rem;
    line-height: .1563rem;
  }
  .tit{
    display: block;
    margin: .1042rem 0;
  }
}

.ul_1 {
  margin-top: .2604rem;
  list-style-type: decimal ;
  margin-left: -1em;
  line-height: .1823rem;
  font-size: .1042rem;
  p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .ul_2 {
    font-size: .0729rem;
    list-style-type: upper-alpha;
    text-align: left;
    line-height: .1302rem;
    padding-left: .026rem;
    margin: .0521rem 0;
    .ul_3{
      font-size: .0729rem;
      list-style-type: disc;
      text-align: left;
      line-height: .1302rem;
      padding-left: .0521rem;
      margin: .0521rem 0;
    }
  }
}
.box_logo{
  width: 200px;
  height: auto;
  position: relative;
  left: 2.0313rem;
  top: .5729rem;
}
@media (max-width: 1000px) {
  .box_logo{
    width: 80px;
    height: auto;
    position: relative;
    left: .1042rem;
    top: 30px;
  }
  .container{
    h1{
      font-size: 24px;
    }
    .tit{
      font-size: 0.01em;
      line-height: 30px;
    }
}
  .ul_1 {
    font-size: .0521rem;
    strong{
      line-height:20px;
    }
    .ul_2{
      line-height: 30px;
      padding-left: .0521rem;
      .ul_3{
        line-height: 30px;
        padding-left: .0521rem;
      }
    }
  }
}

</style>