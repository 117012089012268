<template>
    <div class="gameBady">
        <Header message="/game"></Header>
        <div class="container-up">
            <div class="topbanner  wow animate__animated animate__rotateIn">
                <el-carousel class="games_banner" indicator-position="none" ref="myCarousel" trigger="click" style="width: 5.2083rem; margin: .1563rem auto;" height="2rem" arrow="never">
                    <el-carousel-item class="banner_item" v-for="item in images" :key="item.url">
                        <a :href="item.href" target="blank">
                            <img :src="item.url" class="bannerimg" style="width: 2.0833rem; height: auto; object-fit: cover" />
                        </a>
                        <div class="banner_item_right">
                            <h2 class="item_tit">{{ item.name }}</h2>
                            <span style="white-space: pre-wrap;" class="item_text">{{ item.text }}</span>
                            <!-- <a :href="item.href" target="blank"><img src="../../assets/image/game_store_ggplay.webp" alt=""></a> -->
                            <a :href="item.href" target="blank"><el-button class="">START THE MAGE <i class="iconfont icon-dianji"></i></el-button></a>

                        </div>
                    </el-carousel-item>
                </el-carousel>

            </div>
            <!-- <el-button-group class="topbannerbtn  wow animate__animated animate__rotateIn">
                <el-button icon="el-icon-arrow-left" @click="prevSlide"></el-button>
                <el-button type="warning" @click="nextSlide"><i class="el-icon-arrow-right el-icon--right"></i></el-button>
            </el-button-group> -->

            <div class="game_studio">
                <div class="studio_left wow animate__animated animate__zoomIn">
                <div class="studio_name"><strong>{{ studio_name }}</strong></div>
                <!-- <p class="studio_declaration">{{ studio_declaration }}</p> -->
                <p class="brief_introduction">{{ brief_introduction }}</p>
                </div>
                <div class="studio_right wow animate__animated animate__fadeInLeft">
                    <img src="@/assets/image/falcon-tap-the2.png" alt="">
                </div>
            </div>

            <!-- <div class="container-down wow animate__animated animate__rollIn">
                <div class="boxList">
                    <h6 class="subtitle-frame">
                        All Products
                    </h6>
                    <div class="gameList">
                        <div class="game" v-for="item in gameList" :key="item.link">
                            <div class="border">
                                <a :href="item.href" target="black"><img class="bigimg" :src="item.url" alt=""></a>
                                <div style="display: flex; align-items: center; margin: .026rem .1823rem;">
                                    <a :href="item.href" target="black">
                                        <img class="minimg" :src="item.imgUrl"  style="border: .0052rem solid #475669;">
                                        <span class="gameName">{{ item.name }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Game",
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            studio_name:" Insomniac Games Inc ",
            studio_declaration:"Discover an engaging and captivating online platform filled with a plethora of delightful mini-games, guaranteed to provide endless entertainment and excitement.",
            brief_introduction:"Insomniac Games Inc., a game and software developer headquartered in Wyoming, USA, is looking to partner with a number of outstanding platforms to create a casual gaming platform for overseas users.",
            images: [
                { url: require("@/assets/image/banner_1.png"),name:'GameBox' ,
                    href:'https://gamebox.insomniacd.com/',
                    text:"Discover an engaging and captivating online platform filled with a plethora of delightful mini-games, guaranteed to provide endless entertainment and excitement.",
                },
                
            ],
            // gameList: [
            //     { url: require("@/assets/image/banner_1.png"),imgUrl:require('@/assets/image/banner_1.png'),link:'1',name:'Happy Farm',
            //       href:'https://play.google.com/store/apps/details?id=farm.nongtrai.farming.trangtrai.farmer.lamvuon.bigfarm.farmschool.harvestfarm', 
            //     },
            // ],
        }
    },
    mounted(){
        window.scrollTo(0,0);
    },
    methods: {
        prevSlide() {
            this.$refs.myCarousel.prev();
        },
        nextSlide() {
            this.$refs.myCarousel.next();
        }
    }
}
</script>

<style lang="scss" scoped>
.gameBady {
    background-color: #172532;
    padding-top: .3646rem;
}

.container-up {
    width: 8.8542rem;
    margin: 0 auto;
    position: relative;
}

.topbanner {
    margin: .026rem 0 .1563rem;
    position: relative;
    .games_banner{
        background-color: #212f3c;
        border-radius: .0521rem;
        .banner_item{
            display: flex;
            justify-content: space-between;
            .bannerimg{
                width: 2.1354rem;
                border-radius: .0521rem;
                height: auto;
            }
            .banner_item_right{
                width: 2.6042rem;
                padding:0 .1563rem;
                position: relative;
                .item_tit{
                    width: 2.6042rem;
                    margin: .1563rem auto;
                    font-size: .1875rem;
                    font-weight: bold;
                    color: #ED9A21;
                }
                .item_text{
                    width: 2.6042rem;
                    font-size: .0833rem;
                    color: #a3aaaf;
                    line-height: .1563rem;
                }
                // img{
                //     position: absolute;
                //     width: .7813rem;
                //     height: auto;
                //     left: -0.2865rem;
                //     bottom: .0729rem;
                // }
                a{
                    display: block;
                    margin: .2604rem auto;
                }

            }
        }
        
    }
}

.topbannerbtn {
    position: absolute;
    top: 4.0625rem;
    right: 2.1354rem;
    z-index: 9;
}

.container-down {
    width: 8.8542rem;
    margin: 0 auto;
}

.boxList {
    background: #212f3c;
    border-radius: .0417rem;
    padding: .2083rem .3906rem;
    position: relative;
    z-index: 1;
    width: 95%;
    margin: .5208rem auto;
    h6 {
        font-size: .0938rem;
    }
}

.subtitle-frame {
    margin-left: -0.599rem;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    color: #ED9A21;
    position: relative;
    padding-left: .4688rem;
}

.subtitle-frame::after {
    content: "";
    position: absolute;
    width: .4167rem;
    height: .0052rem;
    background: #ED9A21;
    transform: translate(0, -50%);
    top: 50%;
    left: 0;
}
.gameList{
    width: 7.8281rem;
    padding: .2083rem .2344rem .2083rem .0677rem;
    margin: .2604rem .1849rem;
    display: flex;
    flex-wrap: wrap;
    .game{
        width: 2.2448rem;
        padding: .0521rem;
        position: relative;
        background: #172532;
        border: .0052rem solid rgba(255, 255, 255, 0.1);
        box-shadow: 0rem .0208rem .0208rem rgba(0, 0, 0, 0.1);
        border-radius: .0417rem;
        padding: .0521rem 0 .026rem; 
        margin: .0521rem;
        .border{
            img{
                margin-left: .0521rem;
            }
        }
    }
}
.gameName{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-left: .1042rem;
    color: #FFF;
}
.border{
    text-align: center;

    .bigimg{
        width: 1.8229rem;
        height: auto;
        margin: 0 auto;
        border-radius: .026rem;
    }
}

.minimg{
    width: .2083rem;
    height: .2083rem;
}

.game_studio {
  width: 5.9375rem;
  height: 2.5rem;
  margin-top: .2604rem !important;
  padding: .5208rem 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
}
.studio_left{
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
}
.studio_left>.studio_name{
  font-size: .1875rem;
  line-height: .1354rem;
  color: #FB9C2C;
}
.studio_left>.studio_declaration{
  font-size: .1042rem;
  line-height: .1563rem;
  color: #fff;
  margin: .1563rem 0 .0521rem;
}
.studio_left>.brief_introduction{
  width: 2.8021rem;
  font-size: .0833rem;
  line-height: .1563rem;
  color: #B3B3B3;
}
.studio_left,.studio_right{
  width: 2.8073rem;
  height: 2.2031rem;
}
.studio_right{
    img{
        width: 2.9688rem;
        margin-top: .3125rem;
        border-radius: .0521rem;
        height: auto;
    }

}

</style>